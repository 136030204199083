<template>
    <el-main>
        <div class="container">

            <div class="title">
                <h2>设计 + 协作，摹客就够了</h2>
                <p>一次投入，两份回报。别再分开购买协作平台和设计工具</p>
            </div>

            <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="4" :lg="6" :xl="6">
                    <el-card shadow="hover">
                        鼠标悬浮时显示
                    </el-card>
                </el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="6" :xl="6">
                    <el-card shadow="hover">
                        鼠标悬浮时显示
                    </el-card>
                </el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="6" :xl="6">
                    <el-card shadow="hover">
                        鼠标悬浮时显示
                    </el-card>
                </el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="6" :xl="6">
                    <el-card shadow="hover">
                        鼠标悬浮时显示
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </el-main>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>

.title{
    width: 100%;
    text-align: center;
    margin-top: 60px;
}
.title h2 {
    font-size: 48px;
    line-height: 150%;
    color: #000000;
    font-weight: 600;
}
.title p {
    font-size: 20px;
    line-height: 150%;
    color: #8c8889;
    margin-top: 7px;
}
</style>